<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-date-picker
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clear-icon="meta && meta.clearIcon? meta.clearIcon : 'el-icon-circle-close'"
        :clearable="meta && meta.clearable ? meta.clearable : true"
        :disabled="meta && meta.disabled ? meta.disabled : false"
        :editable="meta && meta.editable ? meta.editable : true"
        :name="field.dbName ? field.dbName : ''"
        :picker-options="pickerOptions"
        :placeholder="meta && meta.placeholder ? meta.placeholder : ''"
        :prefix-icon="meta && meta.prefixIcon? meta.prefixIcon : 'el-icon-date'"
        :readonly="meta && meta.readonly ? meta.readonly : false"
        :size="meta && meta.size ? meta.size : 'small'"
        :type="meta && meta.type ? meta.type : 'datetime'"
        :value="value"
        style="width: 100%"
        v-model="value"
    >
    </el-date-picker>
  </base-form-item>
</template>

<script>
import moment from 'moment';
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'CreateOrUpdate',
  mixins: [abstractForm],
  components: {BaseFormItem},
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: this.disabledDate,
      },
    };
  },
  beforeMount() {
    if (this.value === '0000-00-00 00:00:00') {
      this.value = null;
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== null) {
        const currentDate = moment(newVal);
        const beforeDate = this.meta.before ? moment(this.data[this.meta.before]) : null;
        const afterDate = this.meta.after ? moment(this.data[this.meta.after]) : null;
        if (beforeDate && currentDate.isAfter(beforeDate)) {
          this.value = beforeDate.format('YYYY-MM-DD HH:mm:ss');
        } else if (afterDate && currentDate.isBefore(afterDate)) {
          this.value = afterDate.format('YYYY-MM-DD HH:mm:ss');
        } else {
          this.value = currentDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    },
  },
  methods: {
    disabledDate(date) {
      const currentDate = moment(date.getTime());
      const beforeDate = this.meta.before ? moment(this.data[this.meta.before]) : null;
      const afterDate = this.meta.after ? moment(this.data[this.meta.after]) : null;
      return currentDate.isAfter(beforeDate, 'day') || currentDate.isBefore(afterDate, 'day');
    },
  },
};
</script>

<style scoped>

</style>
